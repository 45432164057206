<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5" v-if="!retrievePolicyRunning">
      <div class="intro-y col-span-12">
        <div class="intro-y box p-5">
          <div class="flex row align-center justify-between">
            <div class="text-lg font-medium mt-3">Dados Principais</div>
            <div class="text-right intro-x" v-show="checkChanges()">
              <button
                type="button"
                class="btn btn-outline-secondary w-24 mr-1"
                @click.prevent="cancel()"
              >
                Descartar
              </button>
              <button
                :disabled="uuid == '612a07715cb0bcaa5568baa0'"
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="save()"
                v-if="uuid != 'create'"
              >
                Salvar
              </button>
              <button
                :disabled="uuid == '612a07715cb0bcaa5568baa0'"
                type="button"
                class="btn btn-primary w-24"
                @click.prevent="create()"
                v-else
              >
                Criar
              </button>
            </div>
          </div>
          <div class="mt-5">
            <label for="group-name" class="form-label">Nome</label>
            <input
              :disabled="uuid == '612a07715cb0bcaa5568baa0'"
              v-model="policyCopy.name"
              id="group-name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="mt-3" v-if="uuid != '612a07715cb0bcaa5568baa0'">
            <label for="group-description" class="form-label">Descrição</label>
            <ClassicEditor
              v-model="policyCopy.description"
              :config="editorConfig"
            />
          </div>
        </div>
        <div class="intro-y box p-5 mt-3 chat">
          <div class="chat__tabs nav nav-tabs gap-2" role="tablist">
            <a
              id="permissions-tab"
              data-toggle="tab"
              data-target="#permissions"
              href="javascript:;"
              class="py-2 px-4 rounded-md text-center active"
              role="tab"
              aria-controls="permissions"
              aria-selected="true"
              >Permissões</a
            >
          </div>
          <div class="tab-content mt-5">
            <div
              id="permissions"
              class="tab-pane active"
              role="tabpanel"
              aria-labelledby="permissions-tab"
            >
              <div class="text-right">
                <Tippy
                  v-if="uuid == '612a07715cb0bcaa5568baa0'"
                  href="javascript:;"
                  class="tooltip"
                  content="É impossivel editar a Política AllPermissions"
                >
                  <button class="btn btn-primary w-36" disabled>
                    Adicionar Permissões
                  </button>
                </Tippy>
                <a
                  v-else
                  class="btn btn-primary w-36"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#modal-addperm"
                >
                  Adicionar Permissões
                </a>
              </div>
              <div
                class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
              >
                <table class="table table-report sm:mt-2">
                  <thead>
                    <tr>
                      <th class="whitespace-nowrap">NOME</th>
                      <th class="whitespace-nowrap">TIPO</th>
                      <th class="whitespace-nowrap">SERVIÇO</th>
                      <th class="text-center whitespace-nowrap">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="action in queriedActions"
                      :key="action._id"
                      class="intro-x"
                    >
                      <td class="w-60">
                        <div class="font-medium whitespace-nowrap">
                          {{ action.name }}
                        </div>
                      </td>
                      <td class="w-30">
                        <div
                          class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                        >
                          {{ action.type }}
                        </div>
                      </td>
                      <td class="w-30">
                        <div
                          class="text-gray-600 text-xs whitespace-nowrap mt-0.5"
                        >
                          {{ action.service }}
                        </div>
                      </td>
                      <td class="table-report__action w-10">
                        <div class="flex justify-center items-center">
                          <button
                            :disabled="uuid == '612a07715cb0bcaa5568baa0'"
                            class="flex items-center text-theme-6"
                            @click="handleDetachAction(action._id)"
                          >
                            <XIcon class="w-5 h-5 mr-1" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="text-center text-lg p-5"
                  v-if="queriedActions.length == 0"
                >
                  No Data
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-8 h-8 mt-5">
      <LoadingIcon icon="puff" class="w-8 h-8" />
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-addperm" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Adicionar Permissões</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <TomSelect
                v-model="actionsToAdd"
                :options="{
                  placeholder: 'Selecione as permissões'
                }"
                multiple
              >
                <option
                  v-for="option in actionsList"
                  :key="option._id"
                  :value="option._id"
                >
                  {{ option.name }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="addAction()"
            >
              Anexar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import _ from 'lodash';

export default {
  setup() {},
  data() {
    return {
      uuid: this.$route.params.id,
      policyCopy: {
        name: '',
        description: '',
        permissions: [],
        _id: ''
      },
      editorConfig: {
        toolbar: {
          items: []
        }
      },
      searchActions: {
        page: 0,
        page_size: 50,
        name: ''
      },
      actionsToAdd: []
    };
  },
  async created() {
    if (this.uuid != 'create') {
      await this.retrievePolicy(this.uuid);
      if (this.policy) {
        this.policyCopy = _.cloneDeep(this.policy);
      }
    }
    this.listActions(this.searchActions);
  },
  computed: {
    ...mapState({
      policy: state => state.policies.policy,
      actions: state => state.permissions.actions,
      retrievePolicyRunning: state => state.policies.retrievePolicyRunning,
      listActionsRunning: state => state.permissions.listActionsRunning,
      attachActionRunning: state => state.policies.attachActionRunning,
      detachActionRunning: state => state.policies.detachActionRunning
    }),
    queriedActions() {
      if (
        this.policyCopy &&
        this.policyCopy.permissions &&
        this.policyCopy.permissions.length > 0
      ) {
        const results = _.cloneDeep(this.policyCopy.permissions);
        results.forEach(r => {
          r.type = this.typeToText(r.type);
        });
        return results;
      }
      return [];
    },
    actionsList() {
      if (
        this.actions &&
        this.actions.results &&
        this.actions.results.length > 0
      ) {
        const results = this.actions.results;
        return results;
      }
      return [];
    }
  },
  methods: {
    ...mapActions('policies', {
      retrievePolicy: 'retrievePolicy',
      attachAction: 'attachAction',
      detachAction: 'detachAction',
      updatePolicy: 'updatePolicy',
      createPolicy: 'createPolicy'
    }),
    ...mapActions('permissions', {
      listActions: 'listActions'
    }),
    checkChanges() {
      if (JSON.stringify(this.policyCopy) != JSON.stringify(this.policy)) {
        return true;
      } else return false;
    },
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    typeToText(type) {
      switch (type) {
        case 'C':
          return 'Criar';
        case 'R':
          return 'Ler';
        case 'U':
          return 'Editar';
        case 'D':
          return 'Deletar';
      }
    },
    async addAction() {
      if (this.actionsToAdd.length > 0) {
        const result = await this.attachAction({
          uuid: this.policyCopy._id,
          permissions: this.actionsToAdd
        });
        if (result == 403) {
          createToast(
            'Você não tem permissão para adicionar essas permissões',
            {
              type: 'danger',
              position: 'top-right',
              hideProgressBar: true,
              showIcon: true,
              transition: 'slide'
            }
          );
        } else if (result) {
          createToast('Ocorreu algum erro ao adicionar essas permissões', {
            type: 'danger',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
        } else {
          createToast('Permissões adicionados com sucesso', {
            type: 'success',
            position: 'top-right',
            hideProgressBar: true,
            showIcon: true,
            transition: 'slide'
          });
          await this.retrievePolicy(this.uuid);
          if (this.policy) {
            this.policyCopy = _.cloneDeep(this.policy);
            this.listActions(this.searchActions);
          }
        }
      }
    },
    async handleDetachAction(action) {
      const permissions = [];
      permissions.push(action);
      const result = await this.detachAction({
        uuid: this.policyCopy._id,
        permissions
      });
      if (result == 403) {
        createToast('Você não tem permissão para remover essa permissão', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao remover a permissão', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Permissão removida com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        await this.retrievePolicy(this.uuid);
        if (this.policy) {
          this.policyCopy = _.cloneDeep(this.policy);
          this.listActions(this.searchActions);
        }
      }
    },
    cancel() {
      this.policyCopy = _.cloneDeep(this.policy);
    },
    async save() {
      const result = await this.updatePolicy(this.policyCopy);
      if (result == 403) {
        createToast('Você não tem permissão para atualizar essa política', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao atualizar a política', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Política atualizada com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        await this.retrievePolicy(this.uuid);
        if (this.policy) {
          this.policyCopy = _.cloneDeep(this.policy);
          this.listActions(this.searchActions);
        }
      }
    },
    async create() {
      const newPolicy = {
        name: this.policyCopy.name,
        description: this.policyCopy.description,
        permissions: this.policyCopy.permissions
      };
      const result = await this.createPolicy(newPolicy);
      if (result == 403) {
        createToast('Você não tem permissão para criar essa política', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao criar a política', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Política criado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.$router.push({ name: 'policies' });
      }
    }
  }
};
</script>
