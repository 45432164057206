<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <!-- BEGIN: Forms -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block sm:flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              <div class="p-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar"
                  v-model="searchArgs.name"
                />
              </div>
            </h2>
            <div class="flex items-center sm:ml-auto mt-3 sm:mt-0">
              <a
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                  mr-2
                "
                href="javascript:;"
                data-toggle="modal"
                data-target="#modal-createuser"
              >
                <PlusIcon class="hidden sm:block w-4 h-4 mr-2" />
                Criar Usuário
              </a>
              <button
                @click.prevent="handleUpdate()"
                class="
                  btn
                  box
                  flex
                  items-center
                  text-white
                  dark:text-gray-300
                  bg-theme-10
                "
              >
                <RefreshCcwIcon class="hidden sm:block w-4 h-4 mr-2" />
                Atualizar
              </button>
            </div>
          </div>
          <div v-if="listUsersRunning" class="mt-5 w-10 h-10">
            <LoadingIcon icon="puff" class="mt-5 w-10 h-10" />
          </div>
          <div
            v-else
            class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0"
          >
            <table class="table table-report sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">USUARIO</th>
                  <th class="whitespace-nowrap">NOME</th>
                  <th class="whitespace-nowrap">EMPRESA</th>
                  <th class="whitespace-nowrap">GRUPO</th>
                  <th class="whitespace-nowrap">EMAIL</th>
                  <th class="text-center whitespace-nowrap">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in queriedData" :key="user._id" class="intro-x">
                  <td class="w-20">
                    <router-link
                      :to="{ name: 'user', params: { id: user._id } }"
                      class="font-medium whitespace-nowrap"
                      >{{ user.username }}</router-link
                    >
                  </td>
                  <td class="w-60">
                    <router-link
                      :to="{ name: 'user', params: { id: user._id } }"
                      class="font-medium whitespace-nowrap"
                      >{{ user.name }}</router-link
                    >
                  </td>
                  <td class="w-60">
                    <Tippy
                      href="javascript:;"
                      class="tooltip"
                      :content="allCompanies(user.company)"
                    >
                      <p class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                        {{ renderCompanies(user.company) }}
                      </p>
                    </Tippy>
                  </td>
                  <td class="w-40">
                    <Tippy
                      href="javascript:;"
                      class="tooltip"
                      :content="allGroups(user.group)"
                    >
                      <p class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                        {{ renderGroups(user.group) }}
                      </p>
                    </Tippy>
                  </td>
                  <td class="w-60">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ user.email[0] }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center">
                      <router-link
                        :to="{ name: 'user', params: { id: user._id } }"
                        class="flex items-center mr-3 text-theme-11"
                      >
                        <EditIcon class="w-5 h-5 mr-1" /> Editar
                      </router-link>
                      <div
                        class="flex items-center text-theme-6 cursor-pointer"
                        @click="handleDeleteUser(user._id)"
                      >
                        <Trash2Icon class="w-5 h-5 mr-1" /> Excluir
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center text-lg p-5" v-if="queriedData.length == 0">
              No Data
            </div>
            <div
              class="
                intro-y
                flex flex-wrap
                sm:flex-row sm:flex-nowrap
                items-center
                mt-3
              "
            >
              <ul class="pagination">
                <li v-if="searchArgs.page > 2">
                  <span class="pagination__link" @click="gotoPage('first')">
                    <ChevronsLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="searchArgs.page > 1">
                  <span class="pagination__link" @click="gotoPage('back')">
                    <ChevronLeftIcon class="w-4 h-4" />
                  </span>
                </li>
                <div v-for="(page, index) in pagesArray" :key="index">
                  <li>
                    <span
                      @click="gotoPage(page)"
                      class="pagination__link"
                      :class="{
                        'pagination__link--active': page == searchArgs.page
                      }"
                      >{{ page }}</span
                    >
                  </li>
                </div>
                <li v-if="qntdPage > searchArgs.page">
                  <span class="pagination__link" @click="gotoPage('next')">
                    <ChevronRightIcon class="w-4 h-4" />
                  </span>
                </li>
                <li v-if="qntdPage > searchArgs.page + 1">
                  <span class="pagination__link" @click="gotoPage('last')">
                    <ChevronsRightIcon class="w-4 h-4" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- END: Forms -->
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-createuser" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mx-auto">Criar Usuário</h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <div class="mt-5">
                <label for="company-name" class="form-label">Nome</label>
                <input
                  v-model="newUser.name"
                  id="company-name"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="mt-3">
                <label for="company-username" class="form-label"
                  >Nome de Usuário</label
                >
                <input
                  v-model="newUser.username"
                  id="company-username"
                  type="text"
                  class="form-control"
                />
              </div>
              <div class="mt-3">
                <label for="company-password" class="form-label">Senha</label>
                <button
                  type="button"
                  class="btn btn-primary btn-sm ml-2"
                  @click.prevent="generatePassword()"
                >
                  Gerar senha
                </button>
                <input
                  v-model="newUser.password"
                  id="company-password"
                  :type="passType"
                  class="form-control"
                />
              </div>
              <div class="form-help" v-show="generatePass">
                Lembre-se de anotar a senha gerada
              </div>
              <div class="mt-3">
                <label for="company-emails" class="form-label">Emails</label
                ><button
                  type="button"
                  class="btn btn-primary btn-sm ml-2"
                  @click.prevent="addMail()"
                >
                  Adicionar email
                </button>
                <div v-for="(mail, index) in mailList" :key="index">
                  <input
                    v-model="mailList[index]"
                    :id="'company-emails-' + index"
                    type="text"
                    class="form-control"
                    :class="{ 'mt-2': index > 0 }"
                  />
                </div>
              </div>
              <div class="mt-3">
                <label>Empresa *</label>
                <div class="mt-2">
                  <TomSelect
                    v-model="companyList"
                    :options="{
                      placeholder: 'Selecione uma empresa'
                    }"
                    multiple
                  >
                    <option
                      v-for="company in companyOptions"
                      :key="company._id"
                      :value="company._id"
                    >
                      {{ company.name }}
                    </option>
                  </TomSelect>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              @click="cancel()"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              data-dismiss="modal"
              @click="handleCreateUser()"
            >
              Criar
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import _ from 'lodash';

export default defineComponent({
  setup() {},
  data() {
    return {
      searchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      },
      oldSearchArgs: {
        page: 1,
        page_size: 10,
        name: ''
      },
      newUser: {
        company: [],
        email: [],
        name: '',
        username: '',
        password: ''
      },
      passType: 'password',
      generatePass: false,
      mailList: [],
      companyOptions: [],
      companyList: []
    };
  },
  async created() {
    await this.listUsers(this.searchArgs);
    await this.listCompanies({});
    this.companyOptions = this.companies.results;
  },
  computed: {
    ...mapState({
      users: state => state.users.users,
      companies: state => state.company.companies,
      listUsersRunning: state => state.users.listUsersRunning
    }),
    queriedData() {
      if (this.users && this.users.results && this.users.results.length > 0) {
        const results = this.users.results;
        results.forEach(r => {
          r.createdAt = this.convertDatetime(r.createdAt);
          r.updatedAt = this.convertDatetime(r.updatedAt);
        });
        return results;
      }
      return [];
    },
    qntdPage() {
      if (this.users && this.users.length) {
        const length = this.users.length;
        return Math.ceil(length / this.searchArgs.page_size);
      } else return 0;
    },
    pagesArray() {
      if (this.users && this.users.length) {
        const array = [];
        for (
          let i = this.searchArgs.page - 1;
          i <=
          (this.qntdPage > 3
            ? this.searchArgs.page + 1 > this.qntdPage
              ? this.qntdPage
              : this.searchArgs.page == 1 && this.qntdPage > 3
              ? 3
              : this.searchArgs.page + 1
            : this.qntdPage);
          i++
        ) {
          if (i > 0) array.push(i);
        }
        return array;
      } else return [];
    }
  },
  methods: {
    ...mapActions('users', {
      listUsers: 'listUsers',
      createUser: 'createUser',
      deleteUser: 'deleteUser'
    }),
    ...mapActions('company', {
      listCompanies: 'listCompanies'
    }),
    convertDatetime(data) {
      data = new Date(data);
      return data.toLocaleString();
    },
    async handleUpdate() {
      await this.listUsers(this.searchArgs);
    },
    renderCompanies(companies) {
      let list;
      let text = '';
      if (companies.length > 2) {
        list = companies.slice(0, 2);
      } else list = companies;
      list.forEach(company => {
        const splittedName = company.name.split(' ');
        if (splittedName.length > 1) {
          text += splittedName.slice(0, 4).join(' ') + ', ';
        } else text += company.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      if (companies.length > 2) text += ', ...';
      return text;
    },
    renderGroups(groups) {
      let list;
      let text = '';
      if (groups.length > 2) {
        list = groups.slice(0, 2);
      } else list = groups;
      list.forEach(group => {
        const splittedName = group.name.split(' ');
        if (splittedName.length > 1) {
          text += splittedName[0] + ' ' + splittedName[1] + ', ';
        } else text += group.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      if (groups.length > 2) text += ', ...';
      return text;
    },
    allCompanies(companies) {
      let text = '';
      companies.forEach(company => {
        text += company.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      return text;
    },
    allGroups(groups) {
      let text = '';
      groups.forEach(group => {
        text += group.name + ', ';
      });
      text = text.substring(0, text.length - 2);
      return text;
    },
    addMail() {
      this.mailList.push('');
    },
    cancel() {
      this.newUser = {
        company: [],
        email: [],
        group: {},
        name: '',
        username: ''
      };
      this.mailList = [];
      this.companyList = [];
    },
    async handleCreateUser() {
      const user = this.newUser;
      user.email = this.mailList.filter(e => e);
      user.company = this.companyList.filter(e => e);
      const result = await this.createUser(user);
      if (result == 403) {
        createToast('Você não tem autorização para criar usuário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao criar o usuário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Usuário criado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.listUsers(this.searchArgs);
      }
    },
    generatePassword() {
      const chars =
        '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const passwordLength = 18;
      let password = '';
      for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }
      this.newUser.password = password;
      this.passType = 'text';
      this.generatePass = true;
    },
    async handleDeleteUser(uuid) {
      const result = await this.deleteUser(uuid);
      if (result == 403) {
        createToast('Você não tem autorização para deleter usuário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else if (result) {
        createToast('Ocorreu algum erro ao deletar o usuário', {
          type: 'danger',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
      } else {
        createToast('Usuário deletado com sucesso', {
          type: 'success',
          position: 'top-right',
          hideProgressBar: true,
          showIcon: true,
          transition: 'slide'
        });
        this.listUsers(this.searchArgs);
      }
    },
    gotoPage(page) {
      if (typeof page == 'number') {
        if (this.searchArgs.page != page) {
          this.searchArgs.page = page;
        }
      } else {
        switch (page) {
          case 'first':
            this.searchArgs.page = 1;
            break;
          case 'back':
            this.searchArgs.page = this.searchArgs.page - 1;
            break;
          case 'next':
            this.searchArgs.page = this.searchArgs.page + 1;
            break;
          case 'last':
            this.searchArgs.page = this.qntdPage;
            break;
        }
      }
    }
  },
  watch: {
    searchArgs: {
      handler: _.debounce(function(val) {
        if (this.oldSearchArgs.name != val.name) {
          val.page = 1;
        }
        this.oldSearchArgs = _.cloneDeep(val);
        this.listUsers(val);
      }, 300),
      deep: true
    }
  }
});
</script>
