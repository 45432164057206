import reportsService from '@/services/reports/reports.service';

const state = {
  report: null,
  reports: [],
  pdf: null,

  createReportRunning: false,
  retrieveReportRunning: false,
  listReportsRunning: false,
  deleteReportRunning: false,
  updateReportRunning: false,
  attachReportRunning: false,
  detachReportRunning: false,
  signReportRunning: false,
  printReportRunning: false
};

const getters = {};

const actions = {
  listReports: async ({ commit }, args) => {
    try {
      let results = reportsService.list(args);
      commit('listReportsRunning', true);
      results = await results;
      commit('listReportsSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retrieveReport: async ({ commit }, args) => {
    try {
      let result = reportsService.retrieve(args);
      commit('retrieveReportRunning', true);
      result = await result;
      commit('retrieveReportSuccess', result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  createReport: async ({ commit }, args) => {
    try {
      commit('createReportRunning', true);
      const results = await reportsService.create(args);
      commit('createReportRunning', false);
      if (results.status == 207) return results.status;
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  updateReport: async ({ commit }, args) => {
    try {
      commit('updateReportRunning', true);
      await reportsService.update(args.uuid, args);
      commit('updateReportRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  deleteReport: async ({ commit }, args) => {
    try {
      commit('deleteReportRunning', true);
      await reportsService.delete(args);
      commit('deleteReportRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  listReportsAppendables: async ({ commit }, args) => {
    try {
      let results = reportsService.listAppendables(args);
      commit('listReportsAppendablesRunning', true);
      results = await results;
      commit('listReportsAppendablesSuccess', results.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  attachReport: async ({ commit }, args) => {
    try {
      commit('attachReportRunning', true);
      await reportsService.attach(args.uuid, args.appends);
      commit('attachReportRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  detachReport: async ({ commit }, args) => {
    try {
      commit('detachReportRunning', true);
      await reportsService.detach(args.uuid, args.appends);
      commit('detachReportRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  signReport: async ({ commit }, args) => {
    try {
      commit('signReportRunning', true);
      await reportsService.sign(args.uuid, args);
      commit('signReportRunning', false);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  printReport: async ({ commit }, args) => {
    try {
      let results = reportsService.print(args);
      commit('printReportRunning', true);
      results = await results;
      commit('printReportSuccess', results);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  }
};

const mutations = {
  listReportsRunning(state, bool = false) {
    state.listReportsRunning = bool;
  },

  listReportsSuccess(state, result) {
    state.reports = result.data;
    state.listReportsRunning = false;
  },

  retrieveReportRunning(state, bool = false) {
    state.retrieveReportRunning = bool;
  },

  retrieveReportSuccess(state, result) {
    state.report = result.data;
    state.retrieveReportRunning = false;
  },

  createReportRunning(state, bool = false) {
    state.createReportRunning = bool;
  },

  updateReportRunning(state, bool = false) {
    state.updateReportRunning = bool;
  },

  deleteReportRunning(state, bool = false) {
    state.deleteReportRunning = bool;
  },

  listReportsAppendablesRunning(state, bool = false) {
    state.listReportsRunning = bool;
  },

  listReportsAppendablesSuccess(state, result) {
    state.reports = result.data;
    state.listReportsRunning = false;
  },

  attachReportRunning(state, bool = false) {
    state.attachReportRunning = bool;
  },

  detachReportRunning(state, bool = false) {
    state.detachReportRunning = bool;
  },

  signReportRunning(state, bool = false) {
    state.signReportRunning = bool;
  },
  printReportRunning(state, bool = false) {
    state.printReportRunning = bool;
  },
  printReportSuccess(state, result) {
    state.pdf = result;
    state.printReportRunning = false;
  }
};

const reports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default reports;
export { reports };
